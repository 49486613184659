import { forwardRef } from "react";

import { Image, type ImageProps } from "@chakra-ui/react";

import type { ModelTypes } from "~/zeus/index";

type Media = ModelTypes["Media"];

function support_format_webp() {
  if (typeof document === "undefined") return false;

  var elem = document.createElement("canvas");
  if (elem.getContext && elem.getContext("2d")) {
    return elem.toDataURL("image/webp").indexOf("data:image/webp") == 0;
  } else {
    return false; // browsers that doesn't support canvas
  }
}

export function resolveMediaToImageProps(
  media: Media
): Pick<ImageProps, "srcSet" | "sizes" | "alt" | "src"> {
  if (!media?.sizes || !media?.url) return {};

  const isMissingSizes = !media?.sizes?.large?.url;

  const srcset = `${media?.sizes?.small?.url} 480w, ${media?.sizes?.medium?.url} 1080w, ${media?.sizes?.large?.url} 1920w`;
  const srcsetJpeg = `${media?.sizes?.small_jpeg?.url} 480w, ${media?.sizes?.medium_jpeg?.url} 1080w, ${media?.sizes?.large_jpeg?.url} 1920w`;
  const src = isMissingSizes
    ? media?.url
    : support_format_webp()
      ? media?.sizes?.large?.url
      : media?.sizes?.large_jpeg?.url;

  const sizes = `(max-width: 480px) 480px, (max-width: 1080px) 1080px, 1920px`;

  return {
    alt: media?.alt,
    ...(isMissingSizes
      ? { src: `${src}` }
      : {
          srcSet: support_format_webp() ? srcset : srcsetJpeg,
          sizes,
          src: `${src}`,
        }),
  };
}

export type CustomImageProps = ImageProps & { media?: Media };

const CustomImage = forwardRef<any, CustomImageProps>((props, ref) => {
  const { media, ...rest } = props;
  return (
    <Image
      loading="lazy"
      ref={ref}
      fallbackSrc="/assets/images/website_placeholder.svg"
      {...rest}
      {...(media ? resolveMediaToImageProps(media) : {})}
    />
  );
});
CustomImage.displayName = "CustomImage";

export default CustomImage;
